import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { setMetaTags } from "../data/helpers.tsx";

const CGV = () => {
  useEffect(() => {
    setMetaTags(
      "CGV | Demande acte de naissance en ligne | acte-naissance-en-ligne.fr ",
      "Conditions générales de vente pour la commande d'actes de naissance en ligne. Service rapide, sécurisé et conforme à la législation. Découvrez nos garanties et modalités",
      "https://www.acte-naissance-en-ligne.fr/cgv"
    );
  }, []);

  return (
    <div className="w-full md:w-4/5 flex flex-col p-5 gap-3 m-2 text-customBlue bg-customLightBlue">
      <h1 className="font-bold text-custom-base">
        {" "}
        CONDITIONS GENERALES DE VENTE{" "}
      </h1>
      <div>
        Les présentes Conditions Générales d’Utilisation et de Services
        (ci-après les « CGV ») ont pour objet de prévoir l’ensemble des
        conditions d’accès, d’utilisation et de consultation par les
        Utilisateurs. :
      </div>
      <div className="font-bold"> Éditeur du site / Hébergement </div>
      <div>
        <b> EI EOS Marketing représentée par A. Beunard (« l’Éditeur ») </b>
        ayant son siège social au Le Lys de Cristal 240 Route de Dordogne 24250
        Groléjac– FRANCE.
      </div>
      <div>Site Internet : www.acte-naissance-en-ligne.fr</div>
      <div>Contact : service-client@acte-naissance-en-ligne.fr</div>
      <div>
        <b> Hébergement : </b> O2SWITCH - Siège social : 222-224 Boulevard
        Gustave Flaubert 63000 Clermont-Ferrand
      </div>
      <div>
        Capital social : 100 000€ - RCS : Clermont-Ferrand – SIRET : 510 909
        80700024
      </div>
      <div className="font-bold">
        L&#39;accès et l&#39;utilisation du site et des services, qu&#39;ils
        soient proposés gratuitement ou contre rémunération, impliquent
        l&#39;acceptation sans réserve des présentes Conditions Générales de
        vente (ci-après les « CGV »), dans leur dernière version, telles
        qu&#39;elles sont accessibles et consultables en permanence sur le site.
      </div>
      <div>
        Sauf accord exprès entre les parties, aucune autre condition générale ou
        spécifique que celles prévues dans les présentes ne pourra être intégrée
        au contrat.
      </div>
      <div>
        Dans le cas exceptionnel où l&#39;Éditeur accepterait formellement les
        Conditions Générales d&#39;Achat de l&#39;Utilisateur, ces dernières ne
        s&#39;appliqueraient qu&#39;à titre supplétif en cas de disposition non
        prévue par les CGUS.
      </div>
      <ol className="flex flex-col gap-3">
        <li className="font-bold md:text-lg">
          <div> 1. DESCRIPTION DU SITE – DEFINITIONS </div>
        </li>
        <ol className="list-decimal flex flex-col gap-3">
          <li className="md:text-lg">
            <div> Objet de l&#39;Éditeur </div>
          </li>
          <div>
            L&#39;Éditeur propose sur le Site des informations thématiques et
            précisément segmentées concernant les collectivités territoriales de
            France, les services publics et la vie citoyenne, ainsi que
            l&#39;ensemble des démarches administratives nécessaires pour
            obtenir, auprès des collectivités territoriales, administrations
            françaises et autres organismes concernés, la délivrance d&#39;actes
            d&#39;état civil.
          </div>
          <li className="md:text-lg">
            <div> Accessibilité des Informations </div>
          </li>
          <div>
            À l&#39;exception de certaines informations et services proposés aux
            Utilisateurs à titre payant suivant les modalités visées sous les
            articles 3, 4 et 5 des présentes CGUS, l&#39;ensemble des
            informations consultables sur le Site sont librement accessibles,
            sous réserve du respect des présentes CGUS.
          </div>
          <li className="md:text-lg">
            <div> Indépendance de l&#39;Éditeur </div>
          </li>
          <div>
            <b>
              L&#39;Éditeur agit en parfaite indépendance à l&#39;égard de
              l&#39;administration, des collectivités territoriales et de
              l&#39;ensemble des acteurs locaux (professionnels, institutions,
              organismes, services publics, etc.) et sites web référencés sur le
              Site. Il est entièrement libre dans le choix de la sélection et de
              l&#39;organisation des informations, des fonctionnalités et des
              services accessibles sur le Site.
            </b>{" "}
            L&#39;Éditeur se réserve la faculté discrétionnaire, à tout moment
            et sans préavis, d&#39;éditer et de supprimer tout ou partie de ces
            informations, ainsi que de modifier ou de suspendre temporairement
            ou définitivement tout ou partie des fonctionnalités et services
            proposés sur le Site.
          </div>
          <li className="md:text-lg">
            <div> Nature des Services </div>
          </li>
          <div>
            <b>
              {" "}
              Le Site et les Services sont fournis à titre privé uniquement et
              ne correspondent en aucune manière à une mission de service public
              déléguée par une quelconque administration publique ou
              collectivité territoriale.{" "}
            </b>
            En souscrivant aux Services visés sous les articles 3 et 4 des
            présentes, l&#39;Utilisateur reconnaît avoir été dûment informé par
            l&#39;Éditeur que les informations et documents qui lui sont
            délivrés dans le cadre de ces Services sont par ailleurs accessibles
            à titre gratuit auprès des collectivités territoriales et
            administrations concernées. La mission de l&#39;Éditeur se limite
            aux Services visés sous les articles 3 et 4. L&#39;Utilisateur
            renonce en conséquence à toute demande de remboursement, en tout ou
            partie, du montant des Services, à toute indemnisation sous quelle
            que forme que ce soit, ainsi qu&#39;à tous recours et actions
            judiciaires motivés par le fait que l&#39;Utilisateur aurait pu
            obtenir les documents ou informations à titre gratuit s&#39;il avait
            effectué l&#39;intégralité des démarches ou recherches
            d&#39;informations par lui-même auprès des collectivités
            territoriales et/ou administrations concernées, sans recourir aux
            Services proposés par l&#39;Éditeur.
          </div>
          <li className="md:text-lg">
            <div> Contact </div>
          </li>
          <div>
            Pour toute question sur le Site et les activités de l&#39;Éditeur,
            les Utilisateurs sont invités à contacter l&#39;Éditeur aux
            coordonnées figurant en tête des présentes CGV ou dans la section
            CONTACT.
          </div>
          <li className="md:text-lg">
            <div> Définitions </div>
          </li>
          <div>
            Au sens des présentes, chacune des expressions ci-dessous commençant
            par une majuscule aura la signification donnée dans sa définition :
            - « <b>Services</b> » : désigne les services fournis à titre payant
            aux Utilisateurs dont le détail et les conditions de souscription
            sont visés sous l&#39;article 3 des présentes CGUS. - «{" "}
            <b>Utilisateur(s)</b> » : désigne toute personne physique ou morale
            se connectant et naviguant sur le Site, et souscrivant
            éventuellement à titre payant aux Services proposés sur le Site.
          </div>
        </ol>
        <li className="font-bold md:text-lg">
          <div> 2. ACCES ET DISPONIBILITE </div>
        </li>
        <div>
          L&#39;accès au Site est assuré de façon continue et permanente, sauf
          en cas de force majeure ou d&#39;événements hors du contrôle de
          l&#39;Éditeur. Cet accès est également soumis aux éventuelles pannes,
          interventions techniques, de maintenance et de réactualisation
          nécessaires au bon fonctionnement du Site, lesquels peuvent entraîner
          des coupures temporaires.
        </div>
        <div>
          En raison de la nature même de l&#39;Internet, l&#39;Éditeur ne peut
          garantir un fonctionnement du Site et des Services 24 heures sur 24 et
          7 jours sur 7. L&#39;Utilisateur reconnaît être informé des
          caractéristiques et des limites de l&#39;Internet, notamment en ce qui
          concerne ses performances techniques, les temps de réponse pour
          consulter, interroger ou transférer des données, ainsi que les risques
          liés à la sécurité des communications.
        </div>
        <li className="font-bold md:text-lg">
          <div>
            3. CONDITIONS PARTICULIERES CONCERNANT LES SERVICES RELATIFS AUX
            DOCUMENTS ETAT CIVIL
          </div>
        </li>
        <ol className="flex flex-col gap-3">
          <li className="font-bold md:text-lg">
            <div>3.1 DESCRIPTION ET CONDITIONS DE FOURNITURE DES SERVICES</div>
          </li>
          <div>
            L&#39;Éditeur propose sur le Site des Services d&#39;accompagnement
            à{" "}
            <b>
              {" "}
              la réalisation de démarches administratives nécessaires pour
              obtenir, auprès des collectivités territoriales et administrations
              concernées, la délivrance d&#39;actes d&#39;état civil,
            </b>{" "}
            pour les citoyens Français
          </div>
          <div>
            L&#39;ensemble des documents visés au présent article 3.1 sont
            désignés ci-après sous le terme « Documents ». Les Services sont
            fournis à titre <b> payant et privé, </b> sous forme d&#39;
            <b>achats à l&#39;unité</b> pour les démarches d&#39;accompagnement
            à l&#39;obtention de Documents.
          </div>
          <div>
            <b> Nos Services </b> consistent à prendre en charge, à votre
            demande,{" "}
            <b>
              {" "}
              les démarches nécessaires pour obtenir des Documents
              administratifs.
            </b>{" "}
            Nous ne vendons ni ne délivrons directement ces Documents, que vous
            pouvez obtenir gratuitement auprès des autorités administratives
            compétentes.
          </div>
          <div>Nos prestations incluent notamment :</div>
          <ul className="list-disc flex flex-col gap-3">
            <li>
              La vérification de l&#39;exhaustivité des informations requises
            </li>
            <li>
              La transmission de ces informations aux autorités concernées
            </li>
            <li>
              Le suivi du dossier et les relances éventuelles jusqu&#39;à
              l&#39;obtention du Document
            </li>
          </ul>
          <div>
            <b> Il est important de noter que : </b>
          </div>
          <div>
            L&#39;Éditeur s&#39;engage à réaliser les démarches avec{" "}
            <b> diligence et soin,</b> mais ne peut garantir le succès de ces
            démarches, qui dépend des délais et procédures propres aux
            administrations concernées.
          </div>
          <div>
            L&#39;Utilisateur est tenu de{" "}
            <b> fournir à l&#39;Éditeur toutes les informations nécessaires </b>{" "}
            à la réalisation des démarches, et de{" "}
            <b> l&#39;informer de tout changement de situation </b> pouvant
            survenir pendant la procédure.
          </div>
          <div>
            Les Services sont fournis à <b> titre payant et privé,</b> selon les
            tarifs en vigueur sur le Site.
          </div>
          <div>
            <b> En cas de questions ou de difficultés, </b> l&#39;Utilisateur
            peut contacter le service client de l&#39;Éditeur via &nbsp;
            <Link
              to="/contact"
              className="underline"
            >
              le formulaire de contact.{" "}
            </Link>
          </div>
          <div>
            L&#39;achat de Services est réservé aux personnes physiques ou
            morales majeures (âgées d&#39;au moins 18 ans) dotées de la capacité
            juridique requise. Pour les personnes morales, le représentant légal
            doit disposer des pouvoirs nécessaires pour engager l&#39;entité.
          </div>
          <div>
            L&#39;Éditeur n&#39;est pas tenu de vérifier la capacité juridique
            de l&#39;Utilisateur ou les pouvoirs du représentant légal. En cas
            d&#39;incapacité juridique, l&#39;Utilisateur ou la personne morale
            sera tenu responsable de la commande et du paiement.
          </div>
          <div>
            L&#39;Éditeur s&#39;engage à réaliser les démarches nécessaires pour
            obtenir les Documents demandés auprès des autorités compétentes. Il
            n&#39;est cependant tenu à aucune obligation de résultat concernant
            la délivrance ou l&#39;exactitude de ces Documents.
            L&#39;Utilisateur ne peut prétendre au remboursement des Services en
            cas de non-délivrance ou d&#39;inexactitude des Documents.
          </div>
          <div>
            L&#39;Éditeur met tout en œuvre pour obtenir les Documents dans les
            meilleurs délais. Cependant, les délais de délivrance indiqués sont
            purement indicatifs et dépendent des administrations concernées.
            L&#39;Éditeur ne peut être tenu responsable des retards de
            délivrance.
          </div>
          <div>
            L&#39;Éditeur ne peut être tenu responsable de l&#39;inexécution des
            Services en cas de force majeure, telle que définie par
            l&#39;article 1218 du Code Civil, ou du fait de l&#39;Utilisateur ou
            d&#39;un tiers.
          </div>
          <div>
            L&#39;Utilisateur doit remplir scrupuleusement le formulaire de
            commande et fournir des informations exactes et sincères.
          </div>
          <div>
            L&#39;Utilisateur reconnaît qu&#39;en cas de non-respect de ses
            obligations de communiquer des informations exactes, l’Éditeur ne
            pourra obtenir ou lui envoyer le Document demandé. Il ne pourra
            prétendre à aucun remboursement ou indemnité.
          </div>
          <div>
            L&#39;Utilisateur doit communiquer à l&#39;Éditeur une adresse
            e-mail valide. Toute communication de l&#39;Éditeur à cette adresse
            sera réputée avoir été réceptionnée et lue par l&#39;Utilisateur.
          </div>
          <div>
            Il est précisé que toute correspondance écrite de l&#39;Éditeur avec
            l&#39;Utilisateur se fera exclusivement par e-mail en cas de
            difficultés liées à sa commande (dossier incomplet, adresse de
            livraison erronée, etc.).
          </div>
          <li className="font-bold md:text-lg">
            <div>3.2 ACHATS DE SERVICES A L&#39;UNITE</div>
          </li>
          <div>
            Dans le cadre d&#39;un achat à l&#39;unité, les{" "}
            <b>
               informations contractuelles ainsi que le détail de la commande,{" "}
            </b>{" "}
            font l&#39;objet d&#39;un <b> récapitulatif détaillé </b> (prix,
            démarches effectuées au titre des Services, nombre d&#39;exemplaires
            et options des Documents concernés, mode et délais de livraison){" "}
            <b> avant la validation définitive du Bon de Commande.</b>
          </div>
          <div>
            L&#39;Utilisateur est invité à relire attentivement le détail de sa
            commande, et à corriger les éventuelles erreurs que celle-ci
            pourrait contenir avant de la valider.
          </div>
          <div>
            Le clic de validation exécuté au terme du processus de commande
            formalise l&#39;agrément par l&#39;Utilisateur du contenu et des
            conditions de la commande, du fait que celle-ci implique une
            obligation de paiement, et vaut acceptation irrévocable des
            présentes Conditions Générales d&#39;Utilisation et de Services.
          </div>
          <div>
            Ce clic de validation vaut consentement définitif à contracter avec
            l&#39;Éditeur pour les Services sélectionnés, sous réserve de
            l&#39;exercice du droit de rétractation réservé aux consommateurs,
            tel que visé sous l&#39;article 4.3 des présentes.
          </div>
          <li className="font-bold md:text-lg">
            <div>3.3 PRIX ET MODALITES DE PAIEMENT</div>
          </li>
          <ol className="list-decimal flex flex-col gap-3 ml-6">
            <li>
              Les prix des Services sont affichés sur le Site en Euros, toutes
              taxes comprises. Ils s&#39;appliquent à tous les Utilisateurs
              souscrivant aux Services proposés sur le Site, qu&#39;ils résident
              en France Métropolitaine ou ailleurs.
            </li>
            <li>
              Les prix des Services sélectionnés par l&#39;Utilisateur
              apparaîtront dans le récapitulatif de commande, toutes taxes
              françaises comprises.
            </li>
            <li>
              L&#39;Éditeur se réserve le droit de modifier ses prix à tout
              moment, mais les Services seront facturés sur la base des tarifs
              en vigueur au moment de l&#39;enregistrement de la commande de
              l&#39;Utilisateur.
            </li>
            <li>
              Les commandes sont payables exclusivement en Euros, en ligne, sur
              le Site, par carte bancaire et par paypal
            </li>
            <li>
              L&#39;Utilisateur devra saisir le numéro, la date d&#39;expiration
              de la carte bancaire, ainsi que le cryptogramme visuel (CVV)
              figurant au verso de la carte bancaire.
            </li>
            <li>
              Les cartes bancaires acceptées sont : Carte Bleue, Visa,
              MasterCard, Amex.
            </li>
          </ol>
          <div className="font-bold">
            L&#39;Éditeur garantit la sécurité et la confidentialité des données
            bancaires de l&#39;Utilisateur.
          </div>
          <div>
            Le paiement s&#39;effectue via des systèmes sécurisés tels que
            Stripe et Paypal, qui utilisent le cryptage SSL pour protéger les
            données personnelles de l&#39;Utilisateur. Toutes les informations
            sensibles, comme les adresses, les e-mails, les numéros de carte
            bancaire et les coordonnées bancaires, sont cryptées au moment de la
            saisie.
          </div>
          <div>
            L&#39;Éditeur n&#39;a jamais accès aux données bancaires de
            l&#39;Utilisateur. Elles sont transmises directement au système de
            paiement via une connexion sécurisée, sans passer par le Site.
          </div>
          <div>
            Toutes les transactions sont protégées par le protocole HTTPS, ce
            qui signifie que les données sensibles (numéro de carte bancaire,
            date de validité) sont chiffrées pour qu’elles ne puissent pas être
            interceptées en clair par un tiers. L&#39;Utilisateur garantit à
            l&#39;Éditeur qu&#39;il dispose des autorisations nécessaires pour
            effectuer le paiement avec le moyen de paiement choisi lors de la
            commande.
          </div>
          <div>
            L&#39;Éditeur se réserve le droit de demander une photocopie de la
            carte d&#39;identité et/ou de la carte bancaire (recto uniquement)
            pour tout paiement par carte bancaire.
          </div>
          <div>
            Dans le cadre de la lutte contre la fraude sur internet, les
            informations relatives à la commande de l&#39;Utilisateur peuvent
            être transmises à des tiers habilités par la loi ou désignés par
            l&#39;Éditeur. Ces tiers auront pour seule mission de vérifier
            l&#39;identité de l&#39;Utilisateur, la validité de la commande, le
            mode de règlement utilisé et la livraison envisagée.
          </div>
          <div>
            Le débit de la carte bancaire de l&#39;Utilisateur s&#39;effectue à
            la date de confirmation de la commande, matérialisée par
            l&#39;e-mail de confirmation de commande (cf. art. 3.2).
          </div>
          <li className="font-bold md:text-lg">
            <div>3.4 DROIT DE RETRACTATION</div>
          </li>
          <div>
            Le droit de rétractation permet aux consommateurs de disposer d’un
            délai de <b> 14 jours pour changer d’avis </b> en cas d
            <b>’achat à distance,</b> que ce soit{" "}
            <b> sur Internet, par téléphone,</b> etc.. L&#39;article L221-18 du
            code de la consommation dispose que le délai de 14 jours court à
            compter du jour de la conclusion du contrat, pour les contrats de
            prestation des services.
          </div>
          <div>
            Conformément à l&#39;article L221-18 du Code de la consommation,{" "}
            <b> l&#39;Utilisateur </b> dispose d&#39;un délai de quatorze (14)
            jours pour exercer son droit de rétractation. Pour ce faire,
            l&#39;Utilisateur doit, avant l&#39;expiration de ce délai, envoyer
            le{" "}
            <a
              href="/files/formulaire-de-retractation.pdf"
              className="underline font-bold"
            >
              {" "}
              formulaire de rétractation
            </a>{" "}
            disponible sur le site à l&#39;adresse du siège social de l&#39;
            <b>Éditeur </b>(mentionnée en tête des présentes CGV). La date du
            cachet de La Poste fera foi pour le respect de ce délai.
          </div>
          <div>
            Conformément à l&#39;article L221-21 du Code de la consommation,
            l&#39;Utilisateur peut également exercer son droit de rétractation
            en adressant à l&#39;Éditeur, avant l&#39;expiration du délai de
            quatorze (14) jours, une déclaration dénuée d&#39;ambiguïté
            exprimant sa volonté de se rétracter. Cette déclaration doit être
            envoyée sur papier libre par courrier à l&#39;Éditeur, la date
            d&#39;envoi faisant foi pour le respect du délai.
          </div>
          <div>
            Pour optimiser la rapidité de nos services et vous fournir les
            Documents demandés dans les plus brefs délais, nous vous proposons,
            lors de la validation de votre commande, de :
          </div>
          <ol className="list-decimal flex flex-col gap-3">
            <li>
              Nous autoriser à commencer l&#39;exécution des Services
              immédiatement, sans attendre la fin du délai de rétractation
              légal.
            </li>
            <li>
              Renoncer à votre droit de rétractation, comme conséquence directe
              de cette autorisation.
            </li>
          </ol>
          <div>
            Cette procédure, conforme à l&#39;article L.221-28 1°) du Code de la
            consommation, vise à accélérer le traitement de votre demande. En
            acceptant ces conditions, vous nous permettez de traiter votre
            commande sans délai, garantissant ainsi une livraison plus rapide
            des Documents sollicités.
          </div>
          <div>
            Votre accord pour ces deux points lors de la validation de votre
            commande nous autorise à agir promptement, tout en respectant le
            cadre légal en vigueur. Cette approche est conçue pour vous offrir
            un service plus efficace et réactif.
          </div>
          <div>
            Si vous choisissez de ne pas autoriser l&#39;exécution immédiate des
            Services, le délai de traitement sera prolongé de 14 jours,
            correspondant au délai légal de rétractation. Dans ce cas, vous
            recevrez généralement vos documents sous 21 jours à compter de la
            date de commande. Notez que pour certains documents officiels, les
            délais peuvent être plus longs et varient selon le volume de
            demandes traitées par les administrations concernées.
          </div>
          <div>
            Après validation de votre commande sur le Site, vous recevrez un
            e-mail de confirmation détaillant tous les éléments de votre
            commande. Assurez-vous que l&#39;adresse e-mail fournie est correcte
            pour recevoir cet accusé de réception.
          </div>
          <div>
            Cet e-mail de confirmation constitue l&#39;acceptation de votre
            commande par l&#39;Éditeur et forme le contrat de vente entre vous
            et l&#39;Éditeur. Il inclura tous les détails de votre commande
            (date, prix, documents commandés, quantités, mode et délais de
            livraison).
          </div>
          <div>
            L&#39;Éditeur se réserve le droit de ne pas confirmer une commande,
            quelle qu&#39;en soit la raison. De plus, l&#39;Éditeur peut
            suspendre ou annuler l&#39;exécution d&#39;une commande à tout
            moment en cas de problème de paiement, de paiement partiel,
            d&#39;incident de paiement, ou de fraude (avérée ou suspectée) liée
            à l&#39;utilisation du Site.
          </div>
          <div>
            Les registres informatiques de l&#39;Éditeur ou de ses
            sous-traitants, conservés dans des conditions de sécurité adéquates,
            seront considérés comme preuves des communications, commandes et
            paiements entre l&#39;Éditeur et l&#39;Utilisateur. L&#39;archivage
            des bons de commande, confirmations de commandes et factures est
            effectué sur un support fiable et durable, pouvant servir de preuve
            si nécessaire.
          </div>
        </ol>
        <li className="font-bold md:text-lg">
          <div>4. RESPONSABILITE</div>
        </li>
        <div>
          L&#39;Éditeur s&#39;engage à fournir des informations fiables et de
          qualité sur le Site, principalement issues de sources publiques. Ces
          données sont librement accessibles et ne font l&#39;objet d&#39;aucune
          restriction de diffusion.
        </div>
        <div>
          L&#39;Éditeur décline toute responsabilité quant aux dommages directs
          ou indirects pouvant résulter de l&#39;utilisation du Site ou des
          informations qui y sont diffusées. De même, l&#39;Éditeur n&#39;est
          pas responsable du contenu des sites externes liés au Site.
        </div>
        <li className="font-bold md:text-lg">
          <div>5. PROPRIETE INTELLECTUELLE</div>
        </li>
        <div>
          Tous les éléments du Site (textes, images, logos, marques, etc.) sont
          protégés par le droit de la propriété intellectuelle et appartiennent
          à l&#39;Éditeur ou à des tiers autorisés.
        </div>
        <div>
          L&#39;Utilisateur bénéficie d&#39;un droit d&#39;usage privé et non
          commercial de ces contenus, dans le respect des fonctionnalités du
          Site et des présentes Conditions. Toute utilisation commerciale,
          reproduction, modification ou rediffusion sans autorisation préalable
          de l&#39;Éditeur est interdite.
        </div>
        <div>
          Le Site dans son ensemble constitue une Base de Données propriété
          exclusive de l&#39;Éditeur, protégée par le Code de la Propriété
          Intellectuelle et la législation européenne sur les Bases de données.
        </div>
        <div>
          Il est donc interdit de reproduire, traduire, adapter, communiquer ou
          distribuer tout ou partie des données du Site, sauf dans les cas
          expressément autorisés. L&#39;utilisation de logiciels
          d&#39;extraction automatique d&#39;informations est également
          prohibée.
        </div>
        <li className="font-bold md:text-lg">
          <div>6. FORCE MAJEURE</div>
        </li>
        <div>
          Conformément à l&#39;article 1218 du Code civil, la Société
          s&#39;engage à maintenir ses offres sur le Site dans les meilleures
          conditions possibles, compte tenu de l&#39;état actuel de la
          technique. Cependant, la Société ne peut être tenue responsable des
          interruptions du Site et de ses offres résultant d&#39;un cas de force
          majeure, du fait d&#39;un tiers, de votre fait, ou des aléas
          techniques inhérents à l&#39;exploitation d&#39;un service en ligne.
        </div>
        <div>
          Les parties conviennent que les événements suivants, sans que cette
          liste soit exhaustive, sont considérés comme des cas de force majeure,
          de cas fortuit ou de fait d&#39;un tiers au sens de l&#39;article 1218
          du Code civil :
        </div>
        <ul className="flex flex-col gap-3">
          <li>- Catastrophes naturelles, incendies, inondations, foudre</li>
          <li>- Surtensions électriques, grèves, coupures d&#39;électricité</li>
          <li>- Défaillances du réseau de télécommunications</li>
          <li>
            - Guerres civiles ou étrangères, émeutes, mouvements populaires,
            attentats
          </li>
          <li>
            - Restrictions réglementaires liées aux services de
            télécommunication
          </li>
          <li>
            - Perte de connectivité due aux opérateurs publics et privés dont
            dépend la Société
          </li>
        </ul>
        <div>
          Conformément à l&#39;article 1231-1 du Code civil, ces cas de force
          majeure suspendent les obligations de la Société énoncées dans ces CGV
          pour toute la durée de leur existence. Si un cas de force majeure
          persiste au-delà de trois (3) mois, chacune des parties peut résilier
          le contrat par lettre recommandée avec avis de réception, en
          application de l&#39;article 1224 du Code civil.
        </div>
        <div>
          En cas de préjudice causé par la faute exclusive de la Société, et en
          application de l&#39;article 1231-3 du Code civil relatif à la
          limitation des dommages et intérêts, la responsabilité de la Société
          est limitée au montant total versé par vos soins dans le cadre du
          contrat.
        </div>
        <li className="font-bold md:text-lg">
          <div>7. DROIT APPLICABLE – REGLEMENT DES LITIGES</div>
        </li>
        <ol className="list-decimal flex flex-col gap-3">
          <li>
            Les présentes conditions sont régies par la loi française. Les
            parties s&#39;engagent à tenter de résoudre tout différend à
            l&#39;amiable avant de saisir la juridiction compétente.
          </li>
          <li>
            L’Utilisateur qui souhaite faire une réclamation doit adresser une
            réclamation écrite à l&#39;Éditeur pour tenter de résoudre le litige
            directement.
          </li>
          <li>
            En cas de litige concernant les prestations régies par ces CGV, les
            parties tenteront d&#39;abord une résolution à l&#39;amiable. Si le
            désaccord persiste, les Tribunaux compétents seront exclusivement
            saisis, même en présence de plusieurs défendeurs.
          </li>
        </ol>
      </ol>
    </div>
  );
};

export default CGV;
