import React, { useEffect } from "react";
import { setMetaTags } from "../data/helpers.tsx";

const PrivacyPoliciy: React.FC<{}> = () => {
  useEffect(() => {
    setMetaTags(
      "Politique de Confidentialité | Demande acte de naissance en ligne | acte-naissance-en-ligne.fr",
      "Découvrez notre politique de confidentialité pour la commande d'actes de naissance en ligne. Sécurité des données, conformité RGPD et transparence garanties.",
      "https://www.acte-naissance-en-ligne.fr/politique-de-confidentialite"
    );
  }, []);

  return (
    <div className="w-full md:w-4/5 flex flex-col gap-4 m-2 p-5 text-customBlue bg-customLightBlue">
      <ol className="flex flex-col list-decimal gap-3">
        <li className="md:text-lg font-bold">
          Objet de notre politique de confidentialité
        </li>
        <div>
          La protection des données sur un site internet consiste à garantir la
          sécurité et la confidentialité des informations personnelles que vous
          partagez en ligne. Cela inclut les mesures prises pour protéger vos
          données contre l&#39;accès non autorisé, les pertes ou les abus, tout
          en respectant les lois en vigueur, telles que le Règlement Général sur
          la Protection des Données (RGPD). Sur notre site, nous nous engageons
          à traiter vos informations de manière transparente, sécurisée, et à
          n&#39;utiliser vos données que pour les finalités pour lesquelles vous
          nous avez donné votre consentement. Votre confiance est essentielle,
          c&#39;est pourquoi la protection de vos données est notre priorité.
        </div>
        <li className="md:text-lg font-bold">Les définitions issues du RGPD</li>
        <div>
          Les définitions suivantes sont celles issues du Règlement général sur
          la protection des données (RGPD) et de la Loi informatique et
          libertés.
        </div>
        <b className="md:text-lg">Donnée personnelle</b>
        <div>
          Une donnée personnelle est toute information se rapportant à une
          personne physique identifiée ou identifiable. Cette personne peut être
          identifiée directement (exemple : nom et prénom) ou indirectement
          (exemple : les cookies)
        </div>
        <b className="md:text-lg">Destinataire</b>
        <div>
          La personne physique ou morale le service ou tout autre organisme
          habilité à obtenir communication de données enregistrées dans un
          fichier ou un traitement en raison de ses fonctions.
        </div>
        <b className="md:text-lg">Fichier</b>
        <div>
          Un fichier est un traitement de données qui s’organise dans un
          ensemble stable et structuré de données. Les données d’un fichier sont
          accessibles selon des critères déterminés.
        </div>
        <b className="md:text-lg">Responsable de traitement</b>
        <div>
          Le responsable de traitement est la personne morale ou physique qui
          détermine les finalités et les moyens d’un traitement, c’est à dire
          l’objectif et la façon de le réaliser.
        </div>
        <b className="md:text-lg">Sous-traitant</b>
        <div>
          Le sous-traitant est la personne physique ou morale qui traite des
          données pour le compte d’un autre organisme (« le responsable de
          traitement »), dans le cadre d’un service ou d’une prestation.
        </div>
        <b className="md:text-lg">Traitement</b>
        <div>
          Un traitement de données personnelles est une opération, ou ensemble
          d’opérations, portant sur des données personnelles, quel que soit le
          procédé utilisé (collecte, enregistrement, conservation, modification,
          consultation, diffusion ou l’effacement de données).
        </div>
        <div>
          On parle de traitement de données dès que les données d&#39;une
          personne sont utilisées d&#39;une manière ou d&#39;une autre et peu
          importe à qui appartiennent ces données
        </div>
        <li className="md:text-lg font-bold">Notre rôle</li>
        <div>
          Dans le cadre de la gestion de notre site internet, nous agissons en
          tant que responsable de traitement. Dans cette hypothèse, nous
          décidons pourquoi (finalités) et comment (moyens) vos données sont
          utilisées.
        </div>
        <div>
          En tant que responsable de traitement, EOS MARKETING s&#39;engage à
          respecter scrupuleusement les obligations découlant du Règlement
          Général sur la Protection des Données (RGPD) et de la Loi Informatique
          et Libertés modifiée. Cela inclut la collecte, le traitement et la
          sécurisation des données à caractère personnel de nos utilisateurs.
          Conformément à l&#39;article 32 du RGPD, nous mettons en œuvre toutes
          les mesures techniques et organisationnelles nécessaires pour garantir
          la protection de vos données personnelles contre toute violation,
          perte, altération ou accès non autorisé.
        </div>
        <div>
          Un interlocuteur privilégié en matière de protection des données
          personnelles peut être contacté à l’adresse suivante : dpo@XXXX
        </div>
        <li className="md:text-lg font-bold">INFORMATIONS COLLECTÉES</li>
        <div>
          En remplissant nos formulaires de collecte disponibles sur notre site,
          vous nous transmettez des données personnelles essentielles telles que
          votre identité, vos coordonnées postales, vos moyens de contact, ainsi
          que des informations concernant votre vie personnelle. Ces données
          peuvent également inclure des informations relatives aux personnes
          pour lesquelles vous effectuez une demande, y compris, le cas échéant,
          celles de descendants ou d’ascendants. Ces données sont nécessaires
          pour qu&#39;EOS MARKETING puisse fournir les services d&#39;assistance
          à la réalisation de démarches administratives, comme les demandes
          d&#39;actes d&#39;état civil.
        </div>
        <div>
          Nous traitons également les informations que vous nous communiquez
          lors de nos échanges à distance, que ce soit par appel téléphonique ou
          par courriel.
        </div>
        <div>
          Les données collectées par EOS MARKETING sont définies comme
          obligatoires ou facultatives. Le caractère obligatoire de certaines
          informations est indiqué par un astérisque sur les formulaires
          correspondants. Conformément au Règlement Général sur la Protection
          des Données (RGPD), nous nous engageons à traiter vos données
          personnelles avec la plus grande rigueur, en respectant vos droits et
          en mettant en œuvre toutes les mesures techniques et
          organisationnelles nécessaires pour garantir leur sécurité.
        </div>
        <div className="font-bold md:text-lg">
          Nous collectons certaines de vos données personnelles de manière
          automatique :
        </div>
        <div>
          Conformément aux dispositions du Règlement Général sur la Protection
          des Données (RGPD), nous collectons automatiquement certaines
          informations vous concernant lorsque vous accédez à notre site,
          l’utilisez, ou réalisez une transaction commerciale avec nous. Ces
          informations incluent :
        </div>
        <div>
          - Données de connexion : Nous recueillons des informations sur
          l’appareil que vous utilisez pour vous connecter à notre site, ainsi
          que sur votre utilisation de celui-ci. Cela comprend des détails tels
          que le système d’exploitation, le type de navigateur, l’utilisation
          éventuelle d’un proxy, la localisation approximative de l’appareil
          déduite de votre adresse IP, les temps d&#39;accès, les pages
          visitées, et l’URL du site qui vous a redirigé vers notre plateforme.
          Ces données sont essentielles pour garantir la sécurité de notre site
          et améliorer votre expérience utilisateur.
        </div>
        <div>
          - Informations sur votre navigation : Nous utilisons des cookies et
          d&#39;autres technologies de suivi pour collecter des informations sur
          votre interaction avec notre site et les communications que nous vous
          envoyons par e-mail. Ces technologies nous permettent d’analyser votre
          comportement de navigation afin de personnaliser votre expérience et
          d’optimiser nos services.
        </div>
        <li className="md:text-lg font-bold">
          Dans quel but et pendant combien de temps utilisons-nous vos données
          personnelles ?
        </li>
        <div>
          <b className="md:text-lg"> Données collectées</b> : Nous recueillons
          plusieurs types de données, y compris des informations relatives à
          votre identité, votre vie personnelle et professionnelle, vos
          informations économiques, vos données de connexion, ainsi que des
          données familiales.
        </div>
        <div>
          <b className="md:text-lg"> Raisons de la collecte </b> : Ces données
          sont utilisées pour plusieurs raisons :
        </div>
        <ul className="list-disc flex flex-col gap-3">
          <li>Gérer la relation commerciale et créer des fiches clients.</li>
          <li>Traiter les notifications de paiement.</li>
          <li>
            Réceptionner et traiter les formulaires de demande d’actes d’état
            civil.
          </li>
          <li>Gérer la base de données des demandes clients.</li>
          <li>
            Contacter les clients par mail pour fournir des informations
            complémentaires.
          </li>
          <li>
            Rassembler les données et les transmettre à l’administration
            concernée.
          </li>
          <li>
            Gérer les réclamations clients et effectuer des relances auprès de
            l’administration concernée.
          </li>
        </ul>
        <div>
          <b className="md:text-lg">Fondement juridique</b> : Le traitement de
          vos données repose principalement sur l&#39;exécution d&#39;un
          contrat. Pour certaines actions, notamment le contrôle et
          l&#39;amélioration de nos sites Internet ainsi que leur sécurisation
          et la prévention contre la fraude, nous nous appuyons sur notre
          intérêt légitime.
        </div>
        <b className="md:text-lg"> Durées de conservation : </b>
        <ul className="flex flex-col gap-3">
          <li>
            - Les données sont conservées pendant toute la durée de la relation
            commerciale puis 3 ans à compter du dernier contact puis archivées à
            titre probatoire pendant 5 ans.
          </li>
          <li>
            - Les pièces comptables sont conservées pendant une durée de 10 ans.
          </li>
        </ul>
        <li className="md:text-lg font-bold">
          Destinataires de vos données personnelles
        </li>
        <div>
          <b className="md:text-lg">Les destinataires internes</b> : Vos données
          personnelles sont exclusivement traitées par les collaborateurs
          habilités de EOS MARKETING. Conformément aux exigences du RGPD, seules
          les données strictement nécessaires à la réalisation des finalités
          prévues sont accessibles aux collaborateurs autorisés, en raison de
          leurs fonctions spécifiques. Toutes les personnes ayant accès à vos
          données personnelles sont soumises à une stricte obligation de
          confidentialité, garantissant la protection de vos informations à
          chaque étape de leur traitement.
        </div>
        <div>
          <b className="md:text-lg">Les destinataires externes</b> : Plusieurs
          catégories de tiers, telles que les prestataires, partenaires,
          fournisseurs et sous- traitants, peuvent avoir accès à vos données
          personnelles. Conformément au RGPD, seules les données strictement
          nécessaires à la réalisation des finalités prévues leur sont
          communiquées.
        </div>
        <div>
          EOS MARKETING est susceptible de transmettre vos données personnelles
          de manière exceptionnelle, aux autorités compétentes (administratives,
          judiciaires, de police) en raison d’une obligation légale ou pour
          garantir les droits, les biens ou la sécurité d’EOS MARKETING et à des
          sous-traitants strictement sélectionnés et indispensables à la bonne
          exécution de nos services
        </div>
        <div>Il s&#39;agit notamment de :</div>
        <ul className="list-disc flex flex-col gap-3">
          <li>
            Stripe et PayPal : solution de paiement en ligne, nécessaire au
            traitement de vos paiements.
          </li>
          <li>
            O2Switch : hébergeur de nos données, assurant la sécurité et
            l&#39;intégrité de vos informations.
          </li>
        </ul>
        <div>
          Nous avons préalablement vérifié la conformité de ces sous-traitants
          au RGPD et nous nous assurons régulièrement qu&#39;ils mettent en
          œuvre les mesures techniques et organisationnelles appropriées pour
          garantir la sécurité et la confidentialité de vos données.
        </div>
        <div>
          En dehors de ces cas strictement limités, EOS MARKETING s&#39;engage à
          ne pas communiquer vos données personnelles à des tiers sans votre
          consentement explicite, sauf obligation légale.
        </div>
        <div>
          Vos données ne font l&#39;objet d&#39;aucune prise de décision
          automatisée. Elles sont traitées de manière humaine et confidentielle.
        </div>
        <li className="md:text-lg font-bold">Quels sont vos droits ?</li>
        <div>
          Conformément au Règlement Général sur la Protection des Données
          (RGPD), vous disposez de droits spécifiques concernant vos données
          personnelles. Vous pouvez à tout moment :
        </div>
        <ul className="list-disc flex flex-col gap-3">
          <li>
            Accéder à vos données : Demander une copie des données que nous
            détenons à votre sujet.
          </li>
          <li>
            Rectifier vos données : Si des informations sont erronées,
            incomplètes ou obsolètes, vous pouvez demander leur rectification.
          </li>
          <li>
            Effacer vos données : Dans certains cas, vous pouvez demander la
            suppression de vos données.
          </li>
          <li>
            Limiter le traitement de vos données : Vous pouvez demander à ce que
            le traitement de vos données soit limité dans certains cas.
          </li>
          <li>
            Vous opposer au traitement de vos données : Vous pouvez vous opposer
            au traitement de vos données, notamment à des fins de prospection
            commerciale.
          </li>
          <li>
            Bénéficier de la portabilité de vos données : Vous pouvez demander à
            recevoir vos données dans un format structuré, couramment utilisé et
            lisible par machine ou à ce que celles-ci soient transmises
            directement à un autre responsable de traitement.
          </li>
        </ul>
        <div>
          Pour exercer ces droits, EOS Marketing n’est pas dans l’obligation de
          désigner un Délégué à la Protection des données mais vous pouvez
          exercer vos droit en nous écrivant par mail à l’adresse suivante :
          rgpd@acte-naissance-en-ligne.fr
        </div>
        <div>
          Si vous souhaitez déposer une réclamation, vous pouvez contacter la
          Commission Nationale de l&#39;Informatique et des Libertés (CNIL) :
        </div>
        <a
          href="https://www.cnil.fr/fr/plaintes"
          className="underline text-bold"
        >
          {" "}
          https://www.cnil.fr/fr/plaintes.{" "}
        </a>
        <li className="md:text-lg font-bold">
          Les transferts de données hors de l’Union Européenne :
        </li>
        <div>
          Nous nous engageons à ne transférer vos données personnelles en dehors
          de l&#39;Union Européenne qu&#39;en respectant strictement les
          exigences du Règlement Général sur la Protection des Données (RGPD).
        </div>
        <div>
          Dans le cas où un tel transfert serait nécessaire, nous mettrons en
          œuvre toutes les garanties appropriées pour assurer un niveau de
          protection adéquat de vos données, notamment :
        </div>
        <ul className="list-disc flex flex-col gap-3">
          <li>
            <b> Vérifications préalables </b> : Nous vérifierons au préalable
            que le pays destinataire assure un niveau de protection adéquat ou
            que des clauses contractuelles types (CCT) ont été adoptées avec le
            destinataire.
          </li>
          <li>
            <b> Information transparente </b> : Nous vous informerons
            préalablement de tout transfert de vos données en dehors de l&#39;UE
            et des garanties mises en place.
          </li>
        </ul>
        <b>
          {" "}
          En règle générale, nous ne prévoyons pas de transfert de vos données
          en dehors de l&#39;UE dans le cadre de nos activités courantes.{" "}
        </b>
        <li className="md:text-lg font-bold">Utilisation de cookies</li>
        <div>
          Notre site utilise des cookies et autres traceurs pour améliorer votre
          expérience utilisateur et personnaliser les contenus.
        </div>
        <b className="md:text-lg">Qu&#39;est-ce qu&#39;un cookie ?</b>
        <div>
          Un cookie est un petit fichier texte déposé sur votre terminal
          (ordinateur, tablette, smartphone) lors de la consultation de notre
          site. Il permet de collecter des informations sur votre navigation et
          de vous reconnaître lors de vos prochaines visites.
        </div>
        <b className="md:text-lg">
          Les différents types de cookies utilisés sur notre site :
        </b>
        <ul className="list-disc flex flex-col gap-3">
          <li>
            <b>Cookies strictement nécessaires</b> : Ces cookies sont
            indispensables au fonctionnement de notre site et vous permettent
            d&#39;utiliser les fonctionnalités essentielles (par exemple, la
            navigation, l&#39;accès à votre compte).
          </li>
          <li>
            <b> Cookies de performance</b> : Ces cookies collectent des
            informations anonymes sur votre utilisation de notre site (pages
            visitées, temps passé, etc.) afin d&#39;améliorer son fonctionnement
            et votre expérience utilisateur.
          </li>
        </ul>
        <b> Gestion de vos cookies </b>
        <div>Vous disposez de différents moyens pour gérer les cookies :</div>
        <ul className="list-disc flex flex-col gap-3">
          <li>
            <b> Bannière de consentement</b> : Lors de votre première visite,
            une bannière vous informe de l&#39;utilisation des cookies et vous
            permet de personnaliser vos choix.
          </li>
          <li>
            <b> Paramètres de votre navigateur</b> : Vous pouvez configurer
            votre navigateur pour accepter ou refuser tous les cookies ou pour
            être informé de leur dépôt.
          </li>
        </ul>
        <b>Conséquences du refus des cookies :</b>
        <div>
          Le refus de certains cookies peut limiter votre expérience utilisateur
          et certaines fonctionnalités du site pourraient ne pas être
          disponibles.
        </div>
        <li className="md:text-lg font-bold">Modification de la politique</li>
        <div>
          Afin de garantir la conformité avec les évolutions législatives et
          réglementaires (notamment le RGPD), nous nous réservons le droit de
          modifier à tout moment la présente politique de confidentialité. Toute
          modification sera effective à compter de sa publication sur notre site
          internet.
        </div>
        <div>Date de la dernière mise à jour : 01 septembre 2024</div>
      </ol>
    </div>
  );
};

export default PrivacyPoliciy;
